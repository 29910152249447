import debounce from 'debounce';
import React, { Suspense, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Container, Icon, LoadingIndicator, MarkdownContent, StyledText } from 'src/components';
import { Contents } from 'src/features/content/config';
import { getRouteBasedOnContentType, markDownToPlainText, trimText } from 'src/helpers';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { useNavigation } from 'src/navigation/hooks';
import { palette, typography } from 'src/styles';

import { Editor } from './Editor';
import { MAIN_NOTE_SECTION_ID } from '../../constants';
import { isContentNoteEditorProps } from '../../helpers';
import { ContentNoteEditorProps, GeneralNoteEditorProps, NoteEditorProps } from '../../types';

export type ChangeParams = { text?: string; title?: string };
type MainBreadcrumbConfig<R extends Route = any> = { route: R; label: string };

export const getMainBreadcrumbConfig = (props: NoteEditorProps): MainBreadcrumbConfig => {
  const generalNoteConfig = {
    route: Route.Home,
    label: i18n.t('notes:general'),
  };

  if (!isContentNoteEditorProps(props)) {
    return generalNoteConfig;
  }

  const { routes, contentTypeSimplified } = Contents.getContentConfig(props.contentType);
  const route = routes.list;

  if (contentTypeSimplified === 'drug') {
    return {
      route,
      label: i18n.t('notes:filterDrugMonograph'),
    };
  }
  if (contentTypeSimplified === 'appendix') {
    return {
      route,
      label: i18n.t('notes:filterAppendix'),
    };
  }
  if (contentTypeSimplified === 'dx-tx') {
    return {
      route,
      label: i18n.t('notes:filterClinicalBriefs'),
    };
  }
  // this should never happen
  return generalNoteConfig;
};

export function NoteEditor(props: ContentNoteEditorProps): React.ReactElement<any, any> | null;
export function NoteEditor(props: GeneralNoteEditorProps): React.ReactElement<any, any> | null;
export function NoteEditor(props: NoteEditorProps): React.ReactElement<any, any> | null {
  const { initialValue, save, title, titleEditable = false, editorRef } = props;

  const navigation = useNavigation();

  const debouncedSave = useMemo(() => debounce(save, 2000), [save]);

  const handleChange = useCallback(
    ({ text, title }: ChangeParams) => {
      if (titleEditable) {
        if (text || title) {
          const titleValue = title! || trimText(markDownToPlainText(text!), 100);
          const value = text || title!;
          debouncedSave({ value, titleValue });
        }
      } else {
        if (text) {
          debouncedSave({ value: text });
        }
      }
    },
    [titleEditable, debouncedSave],
  );

  const mainBreadcrumb = getMainBreadcrumbConfig(props);

  const breadcrumbs = isContentNoteEditorProps(props) ? (
    <View style={styles.breadcrumbsWrapper}>
      <View style={styles.breadcrumbItem}>
        <StyledText
          style={[styles.breadcrumb, styles.blueText]}
          onPress={() => {
            navigation.navigate(mainBreadcrumb.route);
          }}
        >
          {mainBreadcrumb.label}
        </StyledText>
        <Icon name="chevron-right" color={palette.grey3} width={10} style={styles.separatorIcon} />
      </View>
      <View style={styles.breadcrumbItem}>
        <Link
          to={{
            route: getRouteBasedOnContentType(props.contentType),
            params: {
              id: props.contentId,
            },
          }}
          wrapperStyle={styles.link}
        >
          <MarkdownContent inline limited style={[typography.body1SemiBold, styles.blueText]}>
            {props.contentTitle}
          </MarkdownContent>
        </Link>
        <Icon name="chevron-right" color={palette.grey3} width={10} style={styles.separatorIcon} />
      </View>
      {props.sectionId === MAIN_NOTE_SECTION_ID ? (
        <StyledText style={styles.breadcrumb}>{i18n.t('notes:general')}</StyledText>
      ) : (
        <Link
          to={{
            route: getRouteBasedOnContentType(props.contentType),
            params: {
              id: props.contentId,
              section: props.sectionId,
            },
          }}
          wrapperStyle={styles.link}
        >
          <StyledText style={[styles.breadcrumb, styles.blueText]}>{title}</StyledText>
        </Link>
      )}
    </View>
  ) : (
    <StyledText style={styles.breadcrumb}>{i18n.t('notes:general')}</StyledText>
  );

  return (
    <View style={styles.wrapper}>
      <Container style={styles.header}>{breadcrumbs}</Container>
      <Suspense fallback={<LoadingIndicator />}>
        <Editor
          initialValue={initialValue}
          onChange={handleChange}
          title={title}
          showTitle={titleEditable}
          ref={editorRef}
        />
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  breadcrumb: {
    ...typography.body1SemiBold,
    color: palette.grey5,
  },
  blueText: {
    color: palette.blue,
  },
  separatorIcon: {
    marginHorizontal: 8,
  },
  link: {
    flexShrink: 1,
  },
  header: {
    paddingTop: 25,
    paddingBottom: 14,
  },
  breadcrumbItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  breadcrumbsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
});
