import React, { RefObject } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { AccordionsToggle } from 'src/components/AccordionsToggle';
import { StyledText } from 'src/components/StyledText';
import { AccordionRef } from 'src/constants/types/accordion';
import { useUserInfo } from 'src/features/auth/hooks';
import { ContentType, OVERVIEW_TOC_ITEM_ID } from 'src/features/content/constants';
import { ContentItemSection, TableOfContentsProps } from 'src/features/content/types';
import { ContentNotes } from 'src/features/notes';
import { useAccordionToggle } from 'src/hooks/useAccordionToggle';
import { useContentItemAccordionEvents } from 'src/hooks/useContentItemAccordionEvents';
import { useTableOfContentsItems } from 'src/hooks/useTableOfContentsItems';
import { BOTTOM_BAR_HEIGHT, TOC_DESKTOP_PADDING, TOC_DESKTOP_WIDTH, ifWeb, palette } from 'src/styles';

import { TableOfContentsMenu } from './TableOfContensMenu';
import { ContentAccordionProps } from '../ContentAccordion';

interface Props extends TableOfContentsProps {
  top?: number;
  ref: RefObject<View>;
  sections: ContentItemSection[];
  contentType: ContentType;
  contentTitle: string;
  contentId: string;
  accordionRef: RefObject<AccordionRef>;
  notes?: ContentNotes;
  isAnyItemExpanded: boolean | undefined;
  setRemoveNoteConfirmModal: ContentAccordionProps['setRemoveNoteConfirmModal'];
}

export const TableOfContentsDesktop: React.FC<Props> = ({
  item,
  activeSectionId = OVERVIEW_TOC_ITEM_ID,
  onItemPress,
  scrollToTop,
  top = 0,
  ref,
  contentId,
  contentTitle,
  contentType,
  sections,
  accordionRef,
  notes,
  isAnyItemExpanded,
  setRemoveNoteConfirmModal,
}) => {
  const { height: windowHeight } = useWindowDimensions();

  const items = useTableOfContentsItems({ item, onItemPress, scrollToTop });
  const {
    permissions: { hasFullAccessToCoreFuncUXUpdates },
  } = useUserInfo();

  const { onCloseAllPress, onOpenAllPress } = useContentItemAccordionEvents(
    contentId,
    contentTitle,
    contentType,
    sections,
  );

  const { handleToggleAllSectionsPress } = useAccordionToggle({
    notes,
    accordionRef,
    isAnyItemExpanded,
    setRemoveNoteConfirmModal,
    onCloseAllPress,
    onOpenAllPress,
  });

  if (!items.length) return null;

  return (
    <View ref={ref} style={[styles.wrapper, { top, maxHeight: windowHeight - (top + BOTTOM_BAR_HEIGHT) }]}>
      <View testID="content-page-toc" style={styles.tocWrapper}>
        {hasFullAccessToCoreFuncUXUpdates && (
          <View style={styles.tocHeader}>
            <StyledText style={styles.tocTitle}>Contents</StyledText>
            <AccordionsToggle
              openTestID="button-open-all-toc"
              closeTestID="button-close-all-toc"
              onPress={handleToggleAllSectionsPress}
              isAnyItemExpanded={isAnyItemExpanded}
              toc
            />
          </View>
        )}
        <TableOfContentsMenu items={items} active={activeSectionId} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: TOC_DESKTOP_WIDTH,
    paddingRight: TOC_DESKTOP_PADDING,
    paddingLeft: 8,
    position: 'sticky',
    alignSelf: 'flex-start',
  },
  tocHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 24,
    marginBottom: 12,
  },
  tocTitle: {
    fontWeight: '600',
    fontSize: 16,
    color: palette.navy,
  },
  tocWrapper: {
    flex: 1,
    paddingBottom: 20,
    ...ifWeb({
      overflowY: 'auto',
    }),
  },
});
