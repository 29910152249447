import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';

import { ClinicalChallengesBanner, MarkdownContent } from 'src/components';
import { SpecialSectionIDs } from 'src/constants/constants';
import { useUserInfo } from 'src/features/auth/hooks';
import { ContentType, SECTIONS_WITHOUT_NOTES } from 'src/features/content/constants';
import { ContentNote, ExisitingNoteCardProps, NewNoteCardProps, NoteCard } from 'src/features/notes';
import { isDxTx, isContentWithNotes } from 'src/helpers';
import { palette, typography } from 'src/styles';

import { FeatureButton, FeatureButtonProps } from '../FeatureButton';

interface Props {
  sectionId: string;
  contentId: string;
  contentType: ContentType;
  contentTitle: string;
  title: string;
  content?: string;
  containerStyle?: StyleProp<ViewStyle>;
  note?: ContentNote;
  headerButton?: FeatureButtonProps;
  testID?: string;
}

export const Section: React.FC<Props> = ({
  content,
  sectionId,
  contentType,
  contentTitle,
  containerStyle,
  contentId,
  title,
  note,
  headerButton,
}) => {
  const {
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();

  const canAddNote =
    isContentWithNotes({ contentType }) &&
    canAccessIndividualAccountFeatures &&
    !!content &&
    sectionId &&
    !SECTIONS_WITHOUT_NOTES.includes(sectionId);

  const getNoteCardProps = (): NewNoteCardProps | ExisitingNoteCardProps => {
    if (note) return { note, type: 'existing' };

    return {
      type: 'new',
      contentData: {
        sectionId,
        sectionTitle: title,
        contentId,
        contentType,
        contentTitle,
      },
    };
  };

  return (
    <View style={[styles.wrapper, containerStyle]} testID="content-page-section">
      {headerButton && <FeatureButton {...headerButton} style={styles.headerButton} />}
      <MarkdownContent
        style={styles.content}
        horizontalTableRightPadding={16}
        heading1Style={styles.heading1}
      >
        {content}
      </MarkdownContent>
      {sectionId === SpecialSectionIDs.CLINICAL_CHALLENGES && isDxTx({ contentType }) && (
        <ClinicalChallengesBanner hasContent={!!content} />
      )}
      {canAddNote && <NoteCard style={styles.noteCard} {...getNoteCardProps()} />}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    margin: 16,
  },
  headerButton: {
    marginBottom: 16,
  },
  heading1: {
    marginTop: 10,
    marginBottom: 8,
    ...typography.body2,
  },
  content: {
    color: palette.grey8,
    ...typography.body2,
    marginVertical: -8,
  },
  noteCard: {
    marginTop: 16,
  },
});
